import { HttpClient } from "./http-client";

type OrderData = {
  test: boolean;
  directionId: number;
  email: string;
  action: string;
  amount: number;
  account: string;
  requirements: { [key: string]: string };
};

export class Api extends HttpClient {
  public constructor() {
    super("https://api.coinflex.co/");
  }

  public getCryptoMethods = () => {
    return this.instance.get<any[], any>(`directions/crypto/methods`);
  };

  public getFiatMethods = (id: number) => {
    return this.instance.get<any[], any>(`directions/crypto/${id}/fiat/methods`);
  };

  public getDirection = (cryptoId: number, fiatId: number) => {
    return this.instance.get<any[], any>(`directions/find?cryptoId=${cryptoId}&fiatId=${fiatId}`);
  };

  public getCalculate = (amount: number, directionId: number) => {
    return this.instance.get<any[], any>(
      `directions/calculate?amount=${amount}&action=give&directionId=${directionId}`,
      {
        headers: {
          "API-LANG": "en_US",
        },
      }
    );
  };

  public createOrder = (orderData: OrderData) => {
    return this.instance.post<any, any>(`orders/create`, orderData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  public getOrderById = (orderId: string) => {
    return this.instance.get<any, any>(`orders/${orderId}`);
  };
}

const apiCoinFlex = new Api();

export { apiCoinFlex };
