import React from "react";
import { Field, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Button } from "componentsNew";
import FormInput from "../FormInput";
import "./style.scss";
import { useTranslation } from "react-i18next";

export type LoginModel = {
  email: string;
  name: string;
  topic: string;
  message: string;
};

export type LoginFormProps = {
  onSubmit: (values: LoginModel) => void;
  loading: boolean;
};

const LoginSchema = Yup.object().shape({
  name: Yup.string().required("please insert name"),
  email: Yup.string().email("email").required("please insert your email"),
  topic: Yup.string().required("please insert topic"),
  message: Yup.string().required("please insert message"),
});

const LoginForm = ({ onSubmit, loading }: LoginFormProps) => {
  const { t } = useTranslation();
  const initialValues = {
    email: "",
    name: "",
    topic: "",
    message: "",
  };

  const handleSubmit = (values: LoginModel) => {
    onSubmit(values);
  };

  return (
    <div className="exchange_contact_form_wrapper">
      <div className="mainBlock__formBlock">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={LoginSchema}>
          {(formik: FormikProps<LoginModel>) => {
            return (
              <form className="contactForm__form">
                <h2>Contact Us</h2>
                <Field type="name" name="name" component={FormInput} placeholder={t("contactForm.name")} />
                <Field type="email" name="email" component={FormInput} placeholder={t("contactForm.email")} />
                <Field type="text" name="topic" component={FormInput} placeholder={t("contactForm.topic")} />
                <Field type="text" name="message" component={FormInput} placeholder={t("contactForm.message")} />
                <Button onClick={formik.handleSubmit} type="submit" className="contactForm__submitBtn">
                  {loading ? <span>{t("contactForm.loading")}</span> : <span>{t("contactForm.send")}</span>}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default LoginForm;
