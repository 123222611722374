import { apiCoinFlex } from "../api/coinflex";

export interface OrderInfo {
  id: string;
  userId: number;
  directionId: number;
  giveAmount: number;
  swapexId: number;
  getAmount: number;
  account: string;
  hash: string;
  profited: boolean;
  exchanged: boolean;
  depositAddress: string;
  gasSpent: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  direction: {
    id: number;
    rate: number;
    giveMax: number;
    giveMin: number;
    getMin: number;
    getMax: number;
    info: string;
    frozenReason: string;
    fiatReceiveMethodId: number;
    cryptoPaymentMethodId: number;
    updatedAt: string;
    fiat: {
      id: number;
      name: string;
      currency: string;
      logo: string;
      createdAt: string;
      updatedAt: string;
    };
    crypto: {
      id: number;
      name: string;
      chain: number;
      network: string;
      decimals: number;
      contract: string;
      logo: string;
      standard: string;
    };
    requirements: {
      id: string;
      name: string;
      label: string;
      required: boolean;
      field: string;
      type: string;
      orderDirectionId: number;
    }[];
  };
}

async function findOrder(orderId: string) {
  try {
    const response = await apiCoinFlex.getOrderById(orderId);
    return response as OrderInfo;
  } catch (error) {
    console.error(error);
    return;
  }
}

export default findOrder;
