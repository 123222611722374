import React from "react";
import { LayoutExchange } from "../../components/Layout/LayoutExchange";
import "./ExchangePolicy.scss";

import { StyledFlex } from "common/styled-components/helpers";

const ExchangeRules = () => {
  return (
    <LayoutExchange className="exchange-page">
      <StyledFlex align="center" justify="center" direction="column" style={{ flexGrow: "1" }}>
        <div className="policy-section">
          <h2>Policy</h2>
          <span>Policy on Anti Money Laundering and Illegal Transactions.</span>
          <ol>
            <li>
              In accordance with international law, Coinflex adheres to a number of rules and implements a number of
              procedures aimed at preventing the use of the Service for the purpose of money laundering operations, as
              well as other operations of an illegal nature.
            </li>
            <li>
              All the contact information which the User enters in the Application as well as other personal data which
              the User transmits to the Service must be up to date and completely reliable.
            </li>
            <li>
              To prevent transactions of illegal nature, the Service:
              <ul>
                <li>
                  Uses an internal systеm for automated analysis of transactions and User behavior (fraud prevention
                  systеm), which stops all suspicious transactions of the User.
                </li>
                <li>
                  Sets limits on the User’s transactions depending on the level of identification of the User and
                  his/her country of origin.
                </li>
                <li>Carries out verification of all data provided by the User by all available means.</li>
              </ul>
            </li>
            <li>
              The Service reserves the right to freeze all the User’s current requests until it receives from the User
              the copies of their identification documents and other information necessary for checking the transactions
              in the following cases:
              <ul>
                <li>in case of violation of any of the requirements provided in Paragraph 5 of this Agreement;</li>
                <li>if the User’s Application is delayed by the fraud prevention systеm;</li>
                <li>
                  if there is a reasonable suspicion that the User is trying to use the Service for money laundering or
                  any other illegal transaction; In turn, the User undertakes to provide the requested document within 7
                  working days from the receipt of the request for its provision.
                </li>
              </ul>
              <ol>
                <li>
                  In case of the User’s refusal to provide the required documents, the Service reserves the right to
                  refuse service in the future.
                </li>
              </ol>
            </li>
            <li>
              The User guarantees that he is not involved in:
              <ul>
                <li>money laundering operations;</li>
                <li>receiving proceeds from drug trafficking;</li>
                <li>receiving proceeds from criminal and/or terrorist activities;</li>
                <li>receiving proceeds from trade with countries prohibited by international organizations;</li>
                <li>receiving proceeds from any other unlawful activity.</li>
              </ul>
            </li>
            <li>
              The Service reserves the right to deny the User further service and afterwards to transfer all data of the
              User available to the Service, as well as all available information about the User’s transactions to law
              enforcement bodies in cases of:
              <ul>
                <li>
                  the detection of transactions intended for money laundering, financing terrorist organizations, fraud
                  of any kind, as well as transactions aimed at carrying out any other illegal or unlawful operations;
                </li>
                <li>
                  if the Service has a reasonable suspicion that the document presented by the User for the User
                  identification is false or invalid;
                </li>
                <li>
                  the receipt of information from the authorized bodies about the unauthorized possession of the User’s
                  money or digital financial assets, or other information that makes it impossible for the Service to
                  provide services to the User;
                </li>
                <li>
                  discovery of any activity or attempted activity by the User aimed at causing any negative impact on
                  the hardware and software of the Service.
                </li>
                <li>
                  any action or attempted action of the User aimed at theft of data bases and other tangible and
                  intangible property of the Service.
                </li>
                <li>
                  any action or attempted action by the User which may cause any physical, material or non-material
                  damage to the Service.
                </li>
              </ul>
            </li>
            <li>
              The Service reserves the right to verify by all available means the personal and any other information
              received from the User.
            </li>
          </ol>
        </div>
      </StyledFlex>
    </LayoutExchange>
  );
};

export default ExchangeRules;
