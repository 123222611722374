import { ThemeProvider } from "styled-components";
import { hexToRgba } from "./Mixins";

const defaultSizeRem = 10;
const defaultSize = 1.6;

const containerWidth = 104;
const containerPadding = 1.5;
const fontFamily = "'Mulish', sans-serif";

const colors = {
  primary: "#850CE3",
  accent: "#E48900",
  mainBg: "#1C1C1C",
  mainBgLighten: "hsl(0, 0%, 7%)",
  dark: "#10111D",
  light: "#FFFFFF",
  text: {
    gray: hexToRgba("#FFFFFF", 0.4),
  },
  border: hexToRgba("#FFFFFF", 0.1),
};

const transition = "0.1s ease";

const theme = {
  colors,
  transition: transition,
  fonts: {
    defaultSize,
    defaultSizeRem,
    fontFamily,
  },
  container: {
    width: containerWidth,
    gap: containerPadding,
  },

  hover: {
    light: colors.light,
    dark: colors.dark,
    border: "#CACFDB",
    transition: "0.6s ease",
  },
};

export const GlobalThemes = (props) => <ThemeProvider theme={theme} {...props} />;
