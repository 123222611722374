import { apiCoinFlex } from "../api/coinflex";

export interface DirectionInfo {
  id: number;
  giveMax: number;
  giveMin: number;
  getMin: number;
  getMax: number;
}

async function findDirection(cryptoId: number, fiatId: number) {
  try {
    const response = await apiCoinFlex.getDirection(cryptoId, fiatId);
    const { id, giveMax, giveMin, getMin, getMax } = response;

    const directionInfo: DirectionInfo = { id, giveMax, giveMin, getMin, getMax };

    return directionInfo;
  } catch (error) {
    console.error(error);
    return;
  }
}

export default findDirection;
