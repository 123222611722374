import { apiCoinFlex } from "../api/coinflex";

// Интерфейс для одного требования
export interface Requirement {
  id: string;
  name: string;
  required: boolean;
  type: string;
  label: string;
}

// Интерфейс для информации о направлении с учетом требований
export interface CalculatedDirectionInfo {
  amount: number;

  requirements: Requirement[]; // Это массив требований
}

async function calculateDirection(value: number, directionId: number) {
  try {
    const response = await apiCoinFlex.getCalculate(value, directionId);
    const { get, requirements } = response;
    const calculatedDirection: CalculatedDirectionInfo = { amount: get.amount, requirements };
    return calculatedDirection;
  } catch (error) {
    console.error("Error calculating direction:", error);
    throw error;
  }
}

export default calculateDirection;
