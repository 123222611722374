import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import findOrder, { OrderInfo } from "../../services/findOrder";
import { Option, Network } from "components/DropdownExchange";
import { TransactionProgress } from "components/TransactionProgress/TransactionProgress";
import If from "components/If";
import Spinner from "components/Spinner";
import { CreatingError } from "components/CreatingError/CreatingError";
import { LayoutExchange } from "components/Layout/LayoutExchange";
import { StyledFlex } from "common/styled-components/helpers";

function OrderPage() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState<OrderInfo | undefined>();
  const [error, setError] = useState(false);
  const [webSocketStatus, setWebSocketStatus] = useState<{ id: string; status: string; timestamp: number }>();
  const [loading, setLoading] = useState<boolean>(true);
  const socketRef = useRef<WebSocket | null>(null);

  const defaultOption: Option = {
    name: "",
    logo: "",
    info: undefined,
  };

  const defaultNetwork: Network = {
    id: 0,
    symbol: "",
    name: "",
  };

  const [createResponse, setCreateResponse] = useState<any>();
  const [selectedToken, setSelectedToken] = useState<Option>(defaultOption);
  const [selectedFiat, setSelectedFiat] = useState<Option>(defaultOption);
  const [selectedCurrency, setSelectedCurrency] = useState<Network>(defaultNetwork);
  const [account, setAccount] = useState<string>("");

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        if (orderId) {
          const response = await findOrder(orderId);
          setOrderData(response);
          setLoading(false);
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [orderId]);

  useEffect(() => {
    const connectWebSocket = () => {
      // Create WebSocket connection
      socketRef.current = new WebSocket("wss://api.coinflex.co/live");

      socketRef.current.onopen = () => {};

      socketRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          setWebSocketStatus({
            id: data.id,
            status: data.status,
            timestamp: data.timestamp,
          });
        } catch (error) {}
      };

      socketRef.current.onerror = (event) => {
        console.error("WebSocket error observed:", event);
      };

      socketRef.current.onclose = (event) => {
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();
  }, []);

  useEffect(() => {
    if (orderData) {
      setSelectedToken({
        name: orderData?.direction.crypto.name,
        logo: orderData?.direction.crypto.logo,
      });

      setSelectedFiat({
        name: orderData?.direction.fiat.name,
        logo: orderData?.direction.fiat.logo,
      });

      setSelectedCurrency({
        name: orderData?.direction.fiat.currency,
        symbol: orderData?.direction.fiat.currency,
        id: orderData?.direction.fiat.id,
      });

      setAccount(orderData.account);

      setCreateResponse({
        id: orderData.id,
        giveAmount: orderData.giveAmount,
        getAmount: orderData.getAmount,
        status: orderData.status,
      });
    }
  }, [orderData]);

  return (
    <LayoutExchange className="exchange-page">
      <StyledFlex align="center" justify="center" direction="column" style={{ flexGrow: "1" }}>
        <div className={"bridgeExchangeContainer max-width-step3"}>
          <div className="bridgeContainer__actions">
            <div className="creating">
              <If condition={error}>
                <CreatingError />
              </If>
              <If condition={loading}>
                <div className="creating__spinner">
                  <Spinner size={80} />
                </div>
              </If>
              <If condition={!loading}>
                <TransactionProgress
                  selectedToken={selectedToken}
                  selectedCurrency={selectedCurrency}
                  selectedFiat={selectedFiat}
                  account={account}
                  createResponse={createResponse}
                  webSocketStatus={webSocketStatus}
                />
              </If>
            </div>
          </div>
        </div>
      </StyledFlex>
    </LayoutExchange>
  );
}

export default OrderPage;
