import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { GlobalStyle, GlobalThemes } from "common/styled-components/helpers";

import "I18n/config";

ReactDOM.render(
  <GlobalThemes>
    <GlobalStyle />
    <App />
  </GlobalThemes>,

  document.getElementById("root")
);
