import React from "react";
import { LayoutExchange } from "../../components/Layout/LayoutExchange";
import "./ExchangeRules.scss";

import { StyledFlex } from "common/styled-components/helpers";

const ExchangeRules = () => {
  return (
    <LayoutExchange className="exchange-page">
      <StyledFlex align="center" justify="center" direction="column" style={{ flexGrow: "1" }}>
        <div className="rules-section">
          <h2>Rules</h2>
          <span>
            Before using the our service, the User must read in full the Terms and Conditions of Service of the our
            service. The use of the our service is possible only if the User accepts all the terms and conditions of the
            agreement.
          </span>
          <ol>
            <li>
              Terms and Conditions of Service of the our service:
              <ol>
                <li>
                  Our (“Service”) is an electronic financial asset (cryptocurrency) exchange service which enables users
                  to exchange digital financial assets via a special program interface located on the Internet at
                  https://coinflex.co.
                </li>
                <li>
                  A User is any individual/legal entity who agrees to all the terms and conditions offered by the
                  Service and adheres to this agreement.
                </li>
                <li>
                  A Digital Financial Asset is a property in electronic form, created using encryption (cryptographic)
                  tools.
                </li>
                <li>Payment – transfer of electronic units from the payer to the recipient.</li>
                <li>
                  Order – information provided by the User by electronic means of the Service indicating their intention
                  to use the services of the Service under the conditions proposed by the Service and stated in the
                  order parameters.
                </li>
                <li>The User and Coinflex are jointly referred to as the “Parties”.</li>
                <li>
                  Currency exchange – exchange of funds for digital financial assets (cryptocurrency) and vice versa.
                </li>
                <li>Exchange rate – the value ratio of the two exchanged assets.</li>
                <li>
                  Reserves of currencies are the amounts of digital financial assets and funds available to the Service
                  for the performance of services. The amounts of the reserves are available at the Service website at
                  the Home page.
                </li>
                <li>The Service website is https://coinflex.co.</li>
              </ol>
            </li>
            <li>
              Subject of the Agreement and the procedure for its entry into force
              <ol>
                <li>
                  This Agreement regulates the relations between the User and the Service related to the services
                  provided by the Service, and supersedes all previous agreements between the Service and the User on
                  this subject.
                </li>
                <li>
                  This Agreement shall be deemed accepted by the User when submitting the Application, which is an
                  integral part of this Agreement.
                </li>
                <li>
                  The date and time as well as the parameters of the Order conditions are automatically recorded by the
                  Service at the moment the Order is submitted.
                </li>
                <li>This Agreement comes into force at the moment the User completes the formation of the Order.</li>
                <li>
                  This Agreement is terminated at the moment the funds or the financial assets in the amount specified
                  in the User’s Order are sent to the account given by the User, or at the moment of cancelling the
                  Order.
                </li>
                <li>
                  The Magnetic Exchange reserves the right to modify this Agreement unilaterally without prior notice,
                  but with the obligatory publication of the most recent version of the Agreement on this page.
                </li>
              </ol>
            </li>
            <li>
              Subject of the agreement
              <ol>
                <li>
                  The subject of this Agreement is the services of buying and selling digital financial assets, as well
                  as other services described at the Service’s website.
                </li>
                <li>
                  The exchange rate of the Service is set automatically and is published on the Service’s website.
                </li>
                <li>
                  The Service has the right to change the exchange rate unilaterally at any time and notify the Service
                  users by posting the relevant information on the Service website.
                </li>
                <li>
                  In the Order, created by the User on the Service’s website, the direction of the exchange, date and
                  time, as well as the total amount of transferred funds or digital financial assets, are specified.
                </li>
              </ol>
            </li>
            <li>
              Terms and conditions of service provision
              <ol>
                <li>
                  The services of the Service may be ordered by the User by submitting an Order through the Service
                  website.
                </li>
                <li>
                  The management of the exchange process or obtaining information on the progress of the service by the
                  User is carried out by means of the user interface on the Service website.
                </li>
                <li>
                  Processing of the User’s Orders is fulfilled by the Service in compliance with the Privacy Policy
                  (paragraph 5 of the Agreement) and with the Anti-Money Laundering and Money Laundering Prevention
                  Policy (paragraph 6 of the Agreement).
                </li>
                <li>
                  Any completed transaction conducted by the Service according to the User’s Order cannot be cancelled
                  by the User after it has been completed, i.e. after the Service has sent the funds payable to the User
                  according to the previously accepted exchange conditions.
                </li>
                <li>
                  In case the funds or financial assets are not received from the User within one hour from the moment
                  the Order has been submitted by the User, the agreement will be unilaterally terminated by the Service
                  as not having come into effect, without notice.
                </li>
                <li>
                  In case the agreement is terminated, the funds or the digital assets received after the deadline will
                  be returned to the account of the User. When refunds are made, all commission expenses for
                  transferring funds or digital financial assets are made from the received funds at the User’s expense.
                  The Service is not responsible for any delays in refunds if such delays are not the fault of the
                  Service.
                </li>
                <li>
                  If the amount of received funds or digital assets differs from the amount stated by the User, the
                  Service may terminate the agreement unilaterally by refusing to fulfill the request and return the
                  received funds to the sender’s account. When refunding, all commission expenses for the transfer of
                  funds or digital financial assets are made from the received funds at the expense of the User. The
                  Service is not responsible for any delays in refunds if they are not due to the fault of the Service.
                </li>
                <li>
                  In case the Service fails to send the funds or the digital assets to the account specified by the User
                  within 24 hours from the moment the User has submitted their payment, the User may terminate the
                  Agreement by cancelling their request and return the funds or the digital assets in full in case no
                  reason for the Service to block the funds or the digital assets received by their request.
                </li>
                <li>
                  The request for termination will be fulfilled only if the funds and the digital assets have not been
                  sent to the account specified by the User at the moment the request is received.
                </li>
                <li>
                  If a User has paid for an order but due to circumstances wishes to decline the exchange, the refund
                  will be made after deducting the blockchain network transfer fee, the exchange rate difference and
                  costs from 0 to 10% of the payment amount (depending on the payment method and direction of the
                  exchange). In case of termination, the Service will return the funds or the digital assets to the
                  account of the sender within 24 hours from the moment the termination request has been filed. The
                  Service is not responsible for the possible delays in refunds if they are not due to the fault of the
                  Service.
                </li>
                <li>The Service has the right to involve third-party performers to fulfill its obligations.</li>
                <li>
                  The Service reserves the right to cancel the transaction in progress and return the funds or the
                  digital financial assets deposited by the User without explanations.
                </li>
                <li>
                  The Service reserves the right to refuse service in case the User has violated any provision of this
                  Agreement.
                </li>
                <li>
                  All the subsequent requests created by the User after the Service has refused to accept service from
                  the User are automatically frozen.
                </li>
                <li>
                  The Service reserves the right to delay a transaction until the identification of the sender of a
                  funds transfer is completed.
                </li>
                <li>
                  When using the Service, the User confirms that he or she legally owns and manages the funds and
                  digital financial assets involved in the corresponding Payment.
                </li>
                <li>
                  The User undertakes to independently calculate and pay all taxes required under the tax laws of the
                  User’s location.
                </li>
                <li>
                  If the User sends digital financial assets, the application for exchange will be accepted for
                  execution after two confirmations of the transaction.
                </li>
                <li>
                  The User warrants that the User is not involved in:
                  <ul>
                    <li>money laundering transactions;</li>
                    <li>receiving proceeds from illicit drug trafficking;</li>
                    <li>receiving proceeds from criminal and/or terrorist activities;</li>
                    <li>receiving proceeds from trade with countries prohibited by international organizations;</li>
                    <li>receiving proceeds from any other unlawful activity.</li>
                  </ul>
                </li>
                <li>
                  QR codes are given 60 minutes to be removed after issuance. Codes are only created for amounts
                  divisible by 1000, or the application amount will be rounded down.
                </li>
              </ol>
            </li>
            <li>
              Privacy policy
              <ol>
                <li>
                  To perform the transactions, the Service accepts the User’s personal information, which the Service
                  undertakes to keep in encrypted form, not to disclose, not to transfer to the third parties, except
                  for the cases stated in the paragraphs 5.4 and 6.5 of this Agreement.
                </li>
                <li>
                  All the transactions and personal data transfers between the Service and the User are secured with
                  256-bit SSL encryption.
                </li>
                <li>
                  The Service reserves the right to collect additional information about the User, if necessary, by any
                  available means. All the information gained from these activities will not be given to third parties,
                  except for the cases stated in the paragraphs 5.4 and 6.5 of this Agreement.
                </li>
                <li>
                  The Magnetic Exchange reserves the right to share the User’s personal data and the details of their
                  transactions with law enforcement agencies upon their written request / court decision / decision of
                  their own initiative (in case an investigation is needed), or with the User they belong to, on
                  condition that their status remains confidential.
                </li>
              </ol>
            </li>
            <li>
              Policy on Combating Money Laundering and Illegal Transactions
              <ol>
                <li>
                  In accordance with international law, Coinflex follows a number of rules and procedures aimed at
                  preventing the use of the Service for the purpose of money laundering and other operations which are
                  illegal.
                </li>
                <li>
                  To prevent the transactions of illegal character, the Service establishes certain requirements to all
                  the Requests created by the User:
                  <ol>
                    <li>Transfers in favor of third parties are strictly prohibited.</li>
                    <li>
                      All the contact details stated in the User’s Order as well as any other personal information must
                      be truthful and correct.
                    </li>
                    <li>
                      Orders created by the User using anonymous proxy-servers or any other anonymous Internet
                      connections are strictly prohibited.
                    </li>
                  </ol>
                </li>
                <li>
                  To prevent transactions of an illegal nature, the Service <br /> <br /> Uses an internal systеm for
                  automated analysis of the User’s transactions and behavior (a fraud prevention systеm), which stops
                  all suspicious transactions of the User. – Sets limits on the User’s transactions depending on the
                  level of identification of the User and his/her country of origin. – Performs verification of all the
                  data provided by the User by all available means.
                </li>
                <li>
                  The Service reserves the right to freeze all the User’s current requests until it receives from the
                  User copies of the documents confirming their identity, and any other information necessary for
                  checking the transactions in the following cases: <br />
                  <br />– In case of violation of any of the requirements provided in clause 6.2 of this Agreement. – If
                  the User’s Application is delayed by the fraud prevention systеm. – If the Service has a reasonable
                  suspicion that the User is trying to use the Service for money laundering or any other illegal
                  transaction. Refunds are made after full KYC verification by the Service’s security department, which
                  may inсlude detailed verification of the User. Refunds are made after deducting a fee of up to 5% of
                  the transaction amount to cover the labor costs of processing the request and administering the
                  refund.
                  <ol>
                    <li>
                      In case of the User’s failure to provide the required documents, the Service reserves the right to
                      refuse service in further, and take the actions described in the paragraph 4.12 of this Agreement.
                    </li>
                  </ol>
                </li>
                <li>
                  The Magnetic Exchange reserves the right to refuse service in the future and take the actions
                  described in the paragraph 4.6 of this Agreement, and afterwards, transfer all the data of the User
                  and the information about their transactions to the law enforcement authorities in case of <br />{" "}
                  <br />– Revealing transactions aimed at money laundering, financing terrorist organizations, fraud of
                  any kind, as well as transactions aimed at conducting any other illegal or unlawful operations; – If
                  the Service has a reasonable suspicion that the document presented by the User for the User
                  identification is counterfeit or invalid; – The receipt of information from the authorized bodies
                  about the unauthorized possession of the User’s money or digital financial assets, or other
                  information that makes it impossible for the Service to provide services to the User; – Discovery of
                  any actions or attempted actions of the User aimed at causing any negative impact on the hardware and
                  software complex of the Service. – Identification of any actions or attempted actions of the User
                  aimed at theft of databases and other tangible and intangible property of the Service. – Any action or
                  attempted action by the User, which may cause any physical, material or immaterial damage to the
                  Service.
                </li>
                <li>
                  The Service reserves the right to verify all the data provided by the User, employing any available
                  means.
                </li>
              </ol>
            </li>
            <li>
              Limitation of liability
              <ol>
                <li>
                  The Service is not a bank. The Service offers its services 24 hours a day, seven days a week and will
                  make every effort to keep the hardware-software systеm, which realizes the possibilities of the
                  Service, running smoothly.
                </li>
                <li>
                  The Service provides the services on the “as is” basis, as they are described on the Service website
                  and does not offer any additional warranties.
                </li>
                <li>
                  By using this site, the User accepts that the liability of the Service is limited by the means
                  received from the User for processing the subject matter of this Agreement, that the Service gives no
                  additional warranty and assumes no additional responsibility to the User, and that the User has no
                  additional responsibility to the Service, except as stated in the paragraph 7.9.
                </li>
                <li>
                  The Service makes every effort, but does not guarantee that its services will be available 24 hours a
                  day, 7 days a week. The Service assumes no responsibility for any losses, lost profits or other
                  expenses incurred by the User as a result of their inability to access the site or the Service.
                </li>
                <li>
                  The Magnetic Exchange assumes no responsibility for any loss or damage as well as any expenses of the
                  User caused by delays, errors, or failures in processing any bank transfers or electronic currency
                  transfers.
                </li>
                <li>
                  The Magnetic Exchange assumes no responsibility for any damage, loss of profit or any User’s expenses
                  caused by their false expectations in respect of the exchange rates, profitability of the transactions
                  or any other subjective conditions.
                </li>
                <li>
                  In case the User has provided incorrect information about the details of the payee, the Service does
                  not assume any responsibility for any negative consequences or damages caused by this mistake.
                </li>
                <li>
                  By using the Service, the User assumes responsibility to pay taxes in accordance with the tax laws and
                  regulations of the country of their residence. The Service is not a tax agent and will not inform the
                  User of any possible tax charges through the Service.
                </li>
                <li>
                  The User guarantees the reimbursement of losses and damages of the Service in cases of claims,
                  directly or indirectly related to the use of the Service by the User.
                </li>
                <li>
                  The User undertakes not to tamper with the communication process of the Service, not to interfere with
                  its hardware and/or software and not to have any other influence which can disrupt the normal
                  operation of the Service, knowing that such actions will be prosecuted to the full extent of the law.
                </li>
                <li>
                  In case of tampering of communication flows or any negative influence on the normal work of the
                  program code of the Service, which is directly or indirectly related to the User’s application, the
                  execution of the application is stopped, after which the actions described in paragraph 6.5 of this
                  Agreement will be taken.
                </li>
                <li>
                  Neither the User nor the Service assumes any responsibility for delays or inability to fulfill their
                  obligations, caused by force majeure events, including natural disasters, fire, floods, terrorism,
                  power failures, civil unrest, or malfunctioning of the Electronic Payment Systems, power supply
                  systems, communication networks or providers of Internet services.
                </li>
                <li>
                  Electronic Payment Systems and/or financial institutions are solely responsible for the funds
                  entrusted to them by the User. The Service may not be a party to the agreement between the Payment
                  systеm and/or financial institution and the User.
                </li>
                <li>
                  All funds received by the Service three months after the request has been submitted will be at the
                  disposal of the Service. Thereafter, the Service may dispose of these funds at its discretion.
                </li>
                <li>
                  All funds received by the Service without the creation of an application will be at the disposal of
                  the service.
                </li>
                <li>
                  The user who provided incorrect details for the exchange, undertakes to provide correct details for
                  the exchange within three months from the request creation date. Otherwise all the funds are at the
                  disposal of the Service.
                </li>
                <li>
                  In the case of receipt from the User of an amount that differs from the amount specified in the
                  application, the Service may suspend the exchange, and after the User’s application to pay the actual
                  amount received at the exchange rate in effect at the beginning of the transaction or to recalculate
                  the amount independently, without notifying the User about it.
                </li>
              </ol>
            </li>
            <li>
              Order of Acceptance of Claims and Settlement of Disputes
              <ol>
                <li>
                  Claims and disputes, concerning services provided by the Service to the User, will be settled by way
                  of negotiations between the User and the administration of the Service, according to the provisions of
                  this Agreement.
                  <ol>
                    <li>
                      Any claims under this Agreement may be received by the Service electronically by sending a message
                      about the subject of the claim to the details (contact page), specified at the Service website.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Final Provisions
              <ol>
                <li>
                  The Service has the right to send the User e-mail messages about the status of the exchange process
                  and other information, including promotional information.
                </li>
                <li>
                  The information on the site, including graphical images, text information, program codes, etc., is the
                  property of the site and is protected by copyright laws. Each case of unauthorized copying (in whole
                  or in part) may be prosecuted under applicable law.
                </li>
                <li>
                  The User confirms that he has read all the provisions of this Agreement and unconditionally accepts
                  them, otherwise the User may not use the Service.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </StyledFlex>
    </LayoutExchange>
  );
};

export default ExchangeRules;
