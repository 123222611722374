import { apiCoinFlex } from "../api/coinflex";

interface TokenInfo {
  id: number;
  symbol: string;
  name: string;
}

interface TokenData {
  name: string;
  logo: string; // Добавлен ключ logo
  info: TokenInfo[];
}

async function listSources(method: string, id?: number): Promise<TokenData[]> {
  try {
    const tokens: TokenData[] = [];
    const priorityOrder = [
      "Visa/MasterCard",
      "SEPA",
      "Wise",
      "Tinkoff",
      "Revolut",
      "Монобанк",
      "Sberbank",
      "Alfa bank",
      "Any bank",
    ];

    if (method === "crypto") {
      const response = await apiCoinFlex.getCryptoMethods();

      response.forEach((item: any) => {
        const { name, network, id, standard, logo } = item;
        const tokenInfo: TokenInfo = { name: network, id, symbol: standard };

        const existingToken = tokens.find((token) => token.name === name);
        if (existingToken) {
          existingToken.info.push(tokenInfo);
        } else {
          tokens.push({ name, logo, info: [tokenInfo] });
        }
      });
    } else {
      const response = id ? await apiCoinFlex.getFiatMethods(id) : Error;

      if (response) {
        response.forEach((item: any) => {
          const { name, id, logo, currency } = item;
          const tokenInfo: TokenInfo = { name: currency, id, symbol: currency };

          const existingToken = tokens.find((token) => token.name === name);
          if (existingToken) {
            existingToken.info.push(tokenInfo);
          } else {
            tokens.push({ name, logo, info: [tokenInfo] });
          }
        });

        // Фильтрация токенов на основе priorityOrder
        const filteredTokens = tokens.filter((token) => priorityOrder.includes(token.name));

        // Сортировка отфильтрованных токенов
        filteredTokens.sort((a, b) => {
          const indexA = priorityOrder.indexOf(a.name);
          const indexB = priorityOrder.indexOf(b.name);

          if (indexA === -1 && indexB === -1) return 0;
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;
          return indexA - indexB;
        });

        return filteredTokens;
      } else {
        throw new Error("Error fetching data from API");
      }
    }

    return tokens;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
}

export default listSources;
