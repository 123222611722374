import { useState, useEffect } from "react";
import If from "../../If";
import Spinner from "../../Spinner";
import { Option, Network } from "../../DropdownExchange";
import { iconList } from "services/IconList";
import QRCode from "qrcode.react";
import { TransactionProgress } from "components/TransactionProgress/TransactionProgress";
import { createOrder, OrderData } from "services/createOrder";
import { DirectionInfo } from "../../../services/findDiretion";
import { CreatingError } from "components/CreatingError/CreatingError";

interface Props {
  selectedToken: Option;
  selectedFiat: Option;
  selectedNetwork: Network;
  selectedCurrency: Network;
  account: string;
  directionInfo: DirectionInfo | undefined;
  inputValues: { [key: string]: string };
  webSocketStatus: { id: string; status: string; timestamp: number } | undefined;
  amount: number | undefined;
}

export const CreatingOrder = (props: Props) => {
  const {
    selectedCurrency,
    selectedFiat,
    selectedNetwork,
    selectedToken,
    account,
    directionInfo,
    inputValues,
    webSocketStatus,
    amount,
  } = props;

  const [condition, setCondition] = useState<boolean>(false);
  const [createResponse, setCreateResponse] = useState<any>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [txLoading, setTxLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setCreateLoading(true);
    const orderData: OrderData = {
      test: false,
      directionId: directionInfo ? directionInfo.id : 0,
      email: inputValues ? inputValues.cf6 : "",
      action: "give",
      amount: amount ? amount : 0,
      account: inputValues ? inputValues.account2.replace(/\s/g, "") : "",
      requirements: inputValues
        ? Object.fromEntries(Object.entries(inputValues).filter(([key]) => key !== "cf6" && key !== "account2"))
        : {},
    };

    createOrder(orderData)
      .then((response: any) => {
        if (!response) {
          setCreateLoading(false);
          setError(true);
        } else {
          setCreateResponse(response);
          setCreateLoading(false);
          setTxLoading(true);
        }
      })
      .catch(() => {
        setCreateLoading(false);
        setError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToClipboard = (text: string) => {
    if (text) {
      navigator.clipboard.writeText(text);
    }
  };

  return (
    <div className="creating">
      <If condition={error}>
        <CreatingError />
      </If>
      <If condition={createLoading}>
        <div className="creating__spinner">
          <Spinner size={80} />
        </div>
      </If>
      <If condition={condition && !createLoading && txLoading}>
        <TransactionProgress
          selectedToken={selectedToken}
          selectedCurrency={selectedCurrency}
          selectedFiat={selectedFiat}
          account={account}
          createResponse={createResponse}
          webSocketStatus={webSocketStatus}
        />
      </If>
      <If condition={!condition && !createLoading && txLoading}>
        <div className="header__block header__block__creating">
          <h1>Creating Order</h1>
        </div>
        <div className="creating_wrapper">
          <div className="options_wrapper">
            <label className={`creating__label`} htmlFor="creating__defaultValue">
              Amount
            </label>
            <div className="creating__value ">
              <div className="creating__option__value">
                <div className="creating__option">
                  {selectedToken && selectedToken.logo && (
                    <img src={selectedToken.logo} alt={selectedToken.name} width={24} height={24} />
                  )}
                  {createResponse && <span>{createResponse.giveAmount}</span>}
                  {selectedToken && <span>{selectedToken.name}</span>}
                </div>
                <div className="creating__option">
                  {selectedNetwork && iconList[selectedNetwork.name] && (
                    <img src={iconList[selectedNetwork.name]} alt={selectedNetwork.name} width={24} height={24} />
                  )}
                  {selectedNetwork && <span>{selectedNetwork?.symbol}</span>}
                </div>
              </div>
            </div>
          </div>
          <div className="qr-code">
            <QRCode value={createResponse && createResponse.depositAddress} size={95} />
          </div>
        </div>

        <label className={`creating__label`} htmlFor="creating__defaultValue">
          Address
        </label>
        <div className="creating__address">
          <span>{createResponse && createResponse.depositAddress}</span>
          <img
            src={iconList.Copy}
            alt="Copy to clipboard"
            style={{ cursor: "pointer", marginLeft: "8px" }}
            onClick={() => copyToClipboard(createResponse.depositAddress)}
          />
        </div>

        <button
          onClick={() => {
            setCondition(!condition);
          }}
          className="bridgeContainer__nextBtn progress-button"
          disabled={false}
        >
          <span>View Transaction Progress</span>
        </button>
      </If>
    </div>
  );
};
