import React, { useRef, useState, useEffect } from "react";
import "./style.scss";
import Popup from "../Popup";
import If from "../If";
import Spinner from "../Spinner";
import { iconList } from "services/IconList";

export interface Option {
  name: string;
  logo: string;
  info?: any;
}

export interface Network {
  id: number;
  symbol: string;
  name: string;
}

interface IProps {
  selectedOption?: Option;
  selectedNetwork?: Network;
  label: string;
  placeholder: string;
  networks: Network[];
  options: Option[];
  onTokenChange: (option: Option) => void;
  onNetworkChange: (network: Network) => void;
  disabled?: boolean;
  loading?: boolean;
  inputLoading?: boolean;
  value?: number;
  placeholderInput: string;
  onChange: (value?: number) => void;
  disabledInput?: boolean;
  showSearch: boolean;
  onSearchChange: (options: any[]) => void;
}

export const DropdownExchange = ({
  selectedOption,
  selectedNetwork,
  label,
  networks,
  placeholder,
  options,
  onTokenChange,
  onNetworkChange,
  disabled,
  loading,
  inputLoading,
  value,
  placeholderInput,
  onChange,
  disabledInput,
  showSearch,
  onSearchChange,
}: IProps) => {
  const ref = useRef<any>(null);

  const [search, setSearch] = useState("");
  const [openToken, setOpenToken] = useState(false);
  const [openNetwork, setOpenNetwork] = useState(false);
  const [internalValue, setInternalValue] = useState<string | number>("");

  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(parseFloat(value.toFixed(2)));
    } else {
      setInternalValue("");
    }
  }, [value]);

  const handleOnOpenToken = () => {
    if (!disabled) {
      setOpenToken(!openToken);
    }

    if (openNetwork) {
      setOpenNetwork(!openNetwork);
    }
  };

  const handleOnOpenNetwork = () => {
    if (!disabled) {
      setOpenNetwork(!openNetwork);
    }

    if (openToken) {
      setOpenToken(!openToken);
    }
  };

  const handlerIntegerNumber = (val: number | string): number | string => {
    if (typeof val === "string") {
      const floatValue = parseFloat(val);
      if (!isNaN(floatValue)) {
        return floatValue;
      } else {
        return "";
      }
    }
    return val;
  };

  const handlerValue = (val: number): string | number => {
    return isNaN(val) ? "" : val;
  };

  const handlerOnBlur = (e: any): void => {
    setInternalValue(handlerIntegerNumber(e.target.value));
  };

  const handlerOnChange = (e: any): void => {
    const parsedValue = parseFloat(e.target.value);
    onChange(!isNaN(parsedValue) ? parsedValue : undefined);
    setInternalValue(handlerValue(parsedValue));
  };

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    onSearchChange(options);
  }, [options, onSearchChange]);

  const filteredOptions = showSearch
    ? options.filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
    : options;

  return (
    <div ref={ref} className="dropdown-exchange">
      <label
        className={`dropdown__label ${disabled ? "dropdown__label_disabled" : ""}`}
        htmlFor="dropdown__defaultValue"
      >
        {label}
      </label>
      <div className="dropdown__value" aria-disabled={disabled}>
        <div className="dropdown__option__value">
          <div className="dropdown__option" onClick={handleOnOpenToken}>
            <If condition={!!loading}>
              <Spinner size={16} />
            </If>
            <If condition={!loading}>
              {selectedOption && selectedOption.logo && (
                <img src={selectedOption.logo} alt={selectedOption.name} width={24} height={24} />
              )}
              {selectedOption && selectedOption.name !== "" && <span>{selectedOption?.name}</span>}
              {(!selectedOption || !selectedOption.logo) && (
                <img src={iconList.Ellipse} alt="placeholder logo" width={24} height={24} />
              )}
              {(!selectedOption || !selectedOption.logo) && (
                <span className="dropdown__placeholder">Select Method</span>
              )}
            </If>
            <span className="dropdown__arrow">
              <img
                className={`dropdown__arrowImage ${openToken ? "dropdown__arrowImage_rotated" : ""}`}
                src="/images/arrow-down.svg"
                alt="dropdown__arrow"
              />
            </span>
          </div>

          <div className="dropdown__option" onClick={handleOnOpenNetwork}>
            <If condition={!!loading}>
              <Spinner size={16} />
            </If>
            <If condition={!loading}>
              {selectedNetwork && iconList[selectedNetwork.name] && (
                <img src={iconList[selectedNetwork.name]} alt={selectedNetwork.name} width={24} height={24} />
              )}
              {selectedNetwork && <span>{selectedNetwork?.symbol}</span>}
              {(!selectedNetwork || selectedNetwork.id === 0) && (
                <img src={iconList.Ellipse} alt="placeholder logo" width={24} height={24} />
              )}
              {(!selectedNetwork || selectedNetwork.id === 0) && (
                <span className="dropdown__placeholder last_placeholder">{placeholder}</span>
              )}
            </If>
            <span className="dropdown__arrow">
              <img
                className={`dropdown__arrowImage ${openNetwork ? "dropdown__arrowImage_rotated" : ""}`}
                src="/images/arrow-down.svg"
                alt="dropdown__arrow"
              />
            </span>
          </div>
        </div>

        <div className="number-input_exchange__input">
          <If condition={!!inputLoading}>
            <Spinner size={16} />
          </If>
          <If condition={!inputLoading}>
            <input
              type="number"
              value={internalValue}
              onBlur={(e: any) => handlerOnBlur(e)}
              onChange={(e: any) => handlerOnChange(e)}
              placeholder={placeholderInput}
              disabled={disabledInput}
              max={100}
            />
          </If>
        </div>
      </div>
      <div className={`dropdown__list ${openToken ? "show" : ""}`}>
        {showSearch && (
          <div className="exchange_inner_input">
            <img src={iconList.Search} alt="Search icon" width={12} height={12} />
            <input type="text" placeholder="Search" onChange={handleSearch}></input>
          </div>
        )}
        <Popup containerRef={ref} open={openToken} onOpen={(openToken) => !disabled && setOpenToken(openToken)}>
          <div
            className={`dropdown__items-container ${filteredOptions && filteredOptions.length > 1 ? "massive" : ""}`}
          >
            {filteredOptions &&
              filteredOptions.map((option: Option, idx) => (
                <div
                  key={idx}
                  className="dropdown__listItem"
                  onClick={() => {
                    onTokenChange(option);
                    handleOnOpenToken();
                  }}
                >
                  {option.logo && <img src={option.logo} alt={option.name} width={24} height={24} />}
                  {option.name}
                </div>
              ))}
          </div>
        </Popup>
      </div>
      <div className={`dropdown__list ${openNetwork ? "show" : ""}`} onClick={handleOnOpenNetwork}>
        <Popup containerRef={ref} open={openNetwork} onOpen={(openNetwork) => !disabled && setOpenNetwork(openNetwork)}>
          <div className={`dropdown__items-container ${networks && networks.length > 1 ? "massive" : ""}`}>
            {networks &&
              networks.map((network: Network, idx) => (
                <div key={idx} className="dropdown__listItem" onClick={() => onNetworkChange(network)}>
                  {network && iconList[network.name] && (
                    <img src={iconList[network.name]} alt={network.name} width={24} height={24} />
                  )}
                  {network.name}
                </div>
              ))}
          </div>
        </Popup>
      </div>
    </div>
  );
};
