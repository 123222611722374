import { LayoutExchange } from "../../components/Layout/LayoutExchange";
import Exchange from "../../components/Exchange";
import "./ExchangePage.scss";

import { StyledFlex } from "common/styled-components/helpers";

const ExchangePage = () => {
  return (
    <LayoutExchange className="exchange-page">
      <StyledFlex align="center" justify="center" direction="column" style={{ flexGrow: "1" }}>
        <Exchange />
      </StyledFlex>
    </LayoutExchange>
  );
};

export default ExchangePage;
