import "./CreatingError.scss";
import { iconList } from "services/IconList";

export const CreatingError = () => {
  return (
    <div className="creating__error">
      <img src={iconList.Error} alt="Error" />
      <h2>Error</h2>
      <span>An error occurred while creating the order</span>
      <button
        onClick={() => {
          window.location.reload();
        }}
        className="bridgeContainer__nextBtn"
        disabled={false}
      >
        <span>Try Again</span>
      </button>
    </div>
  );
};
