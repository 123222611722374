import React, { useState, useEffect } from "react";
import { Requirement } from "services/calculateDirection";

interface InputValues {
  [key: string]: string;
}

interface Props {
  onNext: () => void;
  onBack: () => void;
  requirements: Requirement[] | undefined;
  inputValues: InputValues;
  setInputValues: (inputValues: InputValues | ((prevValues: InputValues) => InputValues)) => void;
}

export const SetDetails = (props: Props) => {
  const { onNext, onBack, requirements, inputValues, setInputValues } = props;
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    if (requirements) {
      const allRequiredFieldsFilled = requirements
        .filter((requirement) => requirement.required && requirement.type === "text")
        .every((requirement) => inputValues[requirement.name]?.trim().length > 0);

      setIsNextDisabled(!allRequiredFieldsFilled);
    }
  }, [inputValues, requirements]);

  const handleChange = (id: string, value: string) => {
    setInputValues(
      (prevValues: InputValues): InputValues => ({
        ...prevValues,
        [id]: value,
      })
    );
  };

  return (
    <React.Fragment>
      <div className="header__block header__block__details">
        <h1>Exchange Details</h1>
      </div>
      <div className="details-unpit__container">
        {requirements &&
          requirements
            .filter((requirement) => requirement.required && requirement.type === "text")
            .map((requirement) => (
              <div key={requirement.name}>
                <div>
                  <label htmlFor={requirement.name}>{requirement.label || ""}</label>
                </div>
                <div>
                  <input
                    id={requirement.name}
                    type="text"
                    value={inputValues[requirement.name] || ""}
                    onChange={(e) => handleChange(requirement.name, e.target.value)}
                  />
                </div>
              </div>
            ))}
      </div>
      <div className="bridgeContainer__buttons">
        <button onClick={onBack} className="bridgeContainer__backBtn">
          <span>Back</span>
        </button>
        <button onClick={onNext} className="bridgeContainer__nextBtn" disabled={isNextDisabled}>
          <span>Next</span>
        </button>
      </div>
    </React.Fragment>
  );
};
