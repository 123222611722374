import exchange_binance_ico from "../assets/images/exchange_binance_ico.svg";
import exchange_eth_ico from "../assets/images/exchange_eth_ico.svg";
import exchange_eur_ico from "../assets/images/exchange_eur_ico.svg";
import exchange_poly_ico from "../assets/images/exchange_poly_ico.svg";
import exchange_rub_ico from "../assets/images/exchange_rub_ico.svg";
import exchange_gel_ico from "../assets/images/exchange_gel_ico.svg";
import exchange_usd_ico from "../assets/images/exchange_usd_ico.svg";
import ellipse from "../assets/images/exchange_ellipse_ico.svg";
import exchange_search_ico from "../assets/images/exchange_search_ico.svg";
import copy_icon from "../assets/images/copy_icon.svg";
import close_progress_ico from "../assets/images/close_progress_ico.svg";
import create_error from "../assets/images/create-error.svg";

interface ItemList {
  [key: string]: any;
}

export const iconList: ItemList = {
  "BNB Smart Chain": exchange_binance_ico,
  Ethereum: exchange_eth_ico,
  Polygon: exchange_poly_ico,
  RUB: exchange_rub_ico,
  USD: exchange_usd_ico,
  EUR: exchange_eur_ico,
  GEL: exchange_gel_ico,
  Ellipse: ellipse,
  Search: exchange_search_ico,
  Copy: copy_icon,
  Close: close_progress_ico,
  Error: create_error,
};
