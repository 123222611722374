import React, { useState } from "react";
import { LayoutExchange } from "../../components/Layout/LayoutExchange";
import ContactFormExchange from "../../components/ContactFormExchange";
import { StyledFlex } from "common/styled-components/helpers";
import ExchangeLinks from "components/ExchangeLinks";

type LoginModel = {
  email: string;
  name: string;
};

const ExchangeContacts = () => {
  const [loading, setLoading] = useState(false);

  const handleLogin = (credentials: LoginModel) => {
    console.log("credentials-->", credentials);
    setLoading(true);
  };

  return (
    <LayoutExchange className="exchange-page">
      <StyledFlex align="center" justify="center" direction="column" style={{ flexGrow: "1" }}>
        <ContactFormExchange onSubmit={handleLogin} loading={loading}></ContactFormExchange>
        <ExchangeLinks></ExchangeLinks>
      </StyledFlex>
    </LayoutExchange>
  );
};

export default ExchangeContacts;
