import { apiCoinFlex } from "../api/coinflex";

export interface OrderData {
  test: boolean;
  directionId: number;
  email: string;
  action: string;
  amount: number;
  account: string;
  requirements: { [key: string]: string };
}

export interface OrderResponse {
  id: string;
  userId: number;
  directionId: number;
  giveAmount: number;
  getAmount: number;
  account: string;
  hash: string;
  depositAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const createOrder = async (orderData: OrderData): Promise<OrderResponse> => {
  try {
    const response = await apiCoinFlex.createOrder(orderData);
    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to create order: ${error.message}`);
    } else {
      throw new Error("Failed to create order: Unknown error");
    }
  }
};

export { createOrder };
