import React from "react";
import { StyledFlex } from "common/styled-components/helpers";
import { HeaderExchange } from "components/HeaderExchange";

interface Props {
  children: any;
  className?: string;
}

export const LayoutExchange = ({ children, className }: Props) => {
  // @ts-ignore
  return (
    <>
      <HeaderExchange />
      <div className={className}>
        <StyledFlex direction="column" style={{ flexGrow: "1" }}>
          {children}
        </StyledFlex>
      </div>
    </>
  );
};
