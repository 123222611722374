import "./style.scss";
import exchange_mail from "../../assets/images/exchange_mail.svg";
import exchange_tg from "../../assets/images/exchange_tg.svg";
import exchange_twitter from "../../assets/images/exchange_twitter.svg";

export const ExchangeLinks = () => {
  return (
    <div className="exchange_links">
      <div className="exchange_link_1">
        <a href="mailto:coinflexco@gmail.com" target="_blank" rel="noopener noreferrer">
          <img src={exchange_mail} alt="Mail" />
        </a>
      </div>
      <div className="exchange_link_2">
        <a href="https://t.me/coinflexco" target="_blank" rel="noopener noreferrer">
          <img src={exchange_tg} alt="Telegram" />
        </a>
      </div>
      <div className="exchange_link_3">
        <a href="https://x.com/Coinflexco" target="_blank" rel="noopener noreferrer">
          <img src={exchange_twitter} alt="Twitter" />
        </a>
      </div>
    </div>
  );
};

export default ExchangeLinks;
