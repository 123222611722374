import React, { useState } from "react";
import { DropdownExchange, Option, Network } from "../../DropdownExchange";
import { DirectionInfo } from "../../../services/findDiretion";
import { CalculatedDirectionInfo } from "services/calculateDirection";

interface Props {
  onNext: () => void;
  setSelectedToken: (otion: Option) => void;
  setSelectedFiat: (otion: Option) => void;
  setSelectedNetwork: (network: Network) => void;
  setSelectedCurrency: (network: Network) => void;
  onTokenAmountChange: (value: number | undefined) => void;
  setInputLoading: (bool: boolean) => void;
  sourceTokens: any[];
  sourceNetworks: any[];
  targetFiats: any[];
  targetCurrencies: any[];
  selectedToken: Option;
  selectedFiat: Option;
  selectedNetwork: Network;
  selectedCurrency: Network;
  sourceLoading: boolean;
  targetLoading: boolean;
  inputLoading: boolean;
  amount: number | undefined;
  receiveAmount: number | undefined;
  directionInfo: DirectionInfo | undefined;
  calculatedInfo: CalculatedDirectionInfo | undefined;
}

const SelectPayment = (props: Props) => {
  const {
    onNext,
    sourceTokens,
    sourceNetworks,
    targetFiats,
    targetCurrencies,
    setSelectedToken,
    setSelectedFiat,
    selectedToken,
    selectedFiat,
    setSelectedNetwork,
    setSelectedCurrency,
    selectedNetwork,
    selectedCurrency,
    sourceLoading,
    targetLoading,
    inputLoading,
    onTokenAmountChange,
    amount,
    receiveAmount,
    directionInfo,
    calculatedInfo,
    setInputLoading,
  } = props;

  const [showFiatSearch, setFiatSearch] = useState(false);
  const [showTokenSearch, setTokenSearch] = useState(false);

  const handleTokenChange = (option: Option) => {
    setSelectedToken(option);
    setInputLoading(true);
  };

  const handleNetworkChange = (network: Network) => {
    setSelectedNetwork(network);
    setInputLoading(true);
  };

  const handleFiatChange = (option: Option) => {
    setSelectedFiat(option);
    setInputLoading(true);
  };

  const handleCurrencyChange = (network: Network) => {
    setSelectedCurrency(network);
    setInputLoading(true);
  };

  const handleFiatSearch = (options: any[]) => {
    if (options.length > 5) {
      setFiatSearch(true);
    } else {
      setFiatSearch(false);
    }
  };

  const handleTokenSearch = (options: any[]) => {
    if (options.length > 5) {
      setTokenSearch(true);
    } else {
      setTokenSearch(false);
    }
  };

  const isButtonDisabled = (receiveAmount: number | undefined, amount: number | undefined) => {
    if (amount && directionInfo && receiveAmount && calculatedInfo) {
      return !(
        amount >= directionInfo.giveMin &&
        amount <= directionInfo.giveMax &&
        receiveAmount <= directionInfo.getMax &&
        receiveAmount >= directionInfo.getMin
      );
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <div className="header__block">
        <h1>Exchange</h1>
      </div>
      <DropdownExchange
        selectedOption={selectedToken}
        selectedNetwork={selectedNetwork}
        label="Send"
        placeholder="Select Blockchain"
        networks={sourceNetworks}
        options={sourceTokens}
        disabled={false}
        loading={sourceLoading}
        inputLoading={false}
        onTokenChange={handleTokenChange}
        onNetworkChange={handleNetworkChange}
        value={amount}
        placeholderInput={"0"}
        onChange={onTokenAmountChange}
        showSearch={showTokenSearch}
        onSearchChange={handleTokenSearch}
        disabledInput={!(selectedFiat && selectedCurrency && selectedNetwork && selectedToken && directionInfo)}
      />
      <div className="value_range">
        <span>Min: {directionInfo ? parseFloat(directionInfo.giveMin.toFixed(2)) : 0}</span>
        <span>Max: {directionInfo ? parseFloat(directionInfo.giveMax.toFixed(2)) : 0}</span>
      </div>

      <DropdownExchange
        selectedOption={selectedFiat}
        selectedNetwork={selectedCurrency}
        label="Reicive"
        placeholder="Select Currency"
        networks={targetCurrencies}
        options={targetFiats}
        disabled={!(selectedToken && selectedNetwork)}
        loading={targetLoading}
        inputLoading={inputLoading}
        onTokenChange={handleFiatChange}
        onNetworkChange={handleCurrencyChange}
        value={receiveAmount}
        placeholderInput={"0"}
        onChange={onTokenAmountChange}
        showSearch={showFiatSearch}
        onSearchChange={handleFiatSearch}
        disabledInput={true}
      />

      <div className="value_range value_range_last">
        <span>Min: {directionInfo ? parseFloat(directionInfo.getMin.toFixed(2)) : 0}</span>
        <span>Max: {directionInfo ? parseFloat(directionInfo.getMax.toFixed(2)) : 0}</span>
      </div>

      <button
        onClick={() => {
          onNext();
        }}
        className="bridgeContainer__nextBtn"
        disabled={isButtonDisabled(receiveAmount, amount)}
      >
        <span>NEXT</span>
      </button>
    </React.Fragment>
  );
};

export default SelectPayment;
