import React, { useEffect, useState } from "react";
import "./TransactionProgress.scss";
import { Option, Network } from "../DropdownExchange";
import { iconList } from "services/IconList";
import { useNavigate } from "react-router-dom";

interface Props {
  selectedToken: Option;
  selectedFiat: Option;
  selectedCurrency: Network;
  account: string;
  createResponse: any;
  webSocketStatus: { id: string; status: string; timestamp: number } | undefined;
}

export const TransactionProgress = (props: Props) => {
  const { selectedFiat, selectedCurrency, selectedToken, account, createResponse, webSocketStatus } = props;

  const [statusClasses, setStatusClasses] = useState<string[]>(["", "", ""]);
  const [timestamps, setTimestamps] = useState<number[]>([0, 0, 0]);
  const navigate = useNavigate();

  useEffect(() => {
    if (createResponse.status) {
      switch (createResponse.status) {
        case "DEPOSITED":
          setStatusClasses(["active", "with-border", ""]);
          break;
        case "PROCESSED":
          setStatusClasses(["active", "active", "with-border"]);
          break;
        case "COMPLETED":
          setStatusClasses(["active", "active", "active"]);
          break;
        default:
          setStatusClasses(["", "", ""]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (webSocketStatus && webSocketStatus.id === createResponse?.id) {
      let newTimestamps = [...timestamps];
      switch (webSocketStatus.status) {
        case "DEPOSITED":
          setStatusClasses(["active", "with-border", ""]);
          newTimestamps[0] = webSocketStatus.timestamp;
          break;
        case "PROCESSED":
          setStatusClasses(["active", "active", "with-border"]);
          newTimestamps[1] = webSocketStatus.timestamp;
          break;
        case "COMPLETED":
          setStatusClasses(["active", "active", "active"]);
          newTimestamps[2] = webSocketStatus.timestamp;
          break;
        default:
          setStatusClasses(["", "", ""]);
      }
      setTimestamps(newTimestamps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketStatus]);

  const handleBackToExchange = () => {
    navigate("/");
  };

  const formatTimestamp = (timestamp: number) => {
    if (timestamp === 0) return ["", ""];
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    const dateString = `${day}.${month}.${year}`;
    const timeString = date.toLocaleTimeString();
    return [dateString, timeString];
  };

  return (
    <div className="transaction-progress">
      <div className="transaction__wrapper">
        <img src={iconList.Close} alt="Close" className="close-icon" onClick={handleBackToExchange} />
        <div className="header__block header__block__transction">
          <h1>Order ID {createResponse && createResponse.id.slice(0, 8)}</h1>
        </div>
        <div className="plug"></div>
      </div>

      <div className="progress">
        <div className="progress__wrapper">
          <div className="progress__inner">
            <div className={`progress__inner__square ${statusClasses[0]}`}></div>
            <span className="progress__inner__text">Deposit Accepted</span>
          </div>
          <div className="progress__timestamp">
            {formatTimestamp(timestamps[0])[0] && (
              <>
                <span className="date">{formatTimestamp(timestamps[0])[0]}</span>
                <span className="time">{formatTimestamp(timestamps[0])[1]}</span>
              </>
            )}
          </div>
        </div>
        <div className="progress__wrapper">
          <div className="progress__inner">
            <div className={`progress__inner__square ${statusClasses[1]}`}></div>
            <span className="progress__inner__text">Deposit Confirmed</span>
          </div>
          <div className="progress__timestamp">
            {formatTimestamp(timestamps[1])[0] && (
              <>
                <span className="date">{formatTimestamp(timestamps[1])[0]}</span>
                <span className="time">{formatTimestamp(timestamps[1])[1]}</span>
              </>
            )}
          </div>
        </div>
        <div className="progress__wrapper">
          <div className="progress__inner">
            <div className={`progress__inner__square ${statusClasses[2]}`}></div>
            <span className="progress__inner__text">Withdraw Completed</span>
          </div>
          <div className="progress__timestamp">
            {formatTimestamp(timestamps[2])[0] && (
              <>
                <span className="date">{formatTimestamp(timestamps[2])[0]}</span>
                <span className="time">{formatTimestamp(timestamps[2])[1]}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="info">
        <div className="info__inner">
          <span className="info__inner__name">Account</span>
          <div className="info__inner__text">{account}</div>
        </div>
        <div className="info__inner">
          <span className="info__inner__name">Send</span>
          <div className="info__inner__text info__inner__option">
            {selectedToken && selectedToken.logo && (
              <img src={selectedToken.logo} alt={selectedToken.name} width={24} height={24} />
            )}
            {selectedToken && <span>{selectedToken.name}</span>}
            {createResponse && <span>{createResponse.giveAmount}</span>}
          </div>
        </div>
        <div className="info__inner">
          <span className="info__inner__name">Receive</span>
          <div className="info__inner__text info__inner__option">
            {selectedFiat && selectedFiat.logo && (
              <img src={selectedFiat.logo} alt={selectedFiat.name} width={24} height={24} />
            )}
            {selectedCurrency && <span>{selectedCurrency.name}</span>}
            {createResponse && <span>{createResponse.getAmount}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
