import React, { useState } from "react";
import cn from "classnames";
import { useMedia } from "use-media";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./HeaderExchange.scss";
import logoImg from "assets/images/logoBig.svg";
import logoMobileImg from "assets/images/logo.svg";
import menuImg from "assets/images/menu.svg";
import closeImg from "assets/images/close.svg";
import { useScrollPosition } from "hooks/useScrollPosition";
import { ROUTES } from "common/constants/routesExchange";
import { mixins } from "helpers/mixins";
import LanguageSwitcher from "../LanguageSwitcher";
import { IconPolygon } from "../../assets/icons";
import classNames from "classnames";

export const HeaderExchange = (): JSX.Element => {
  const scroll = useScrollPosition();
  const [sidebar, setSidebar] = useState(false);
  const isM = useMedia({ maxWidth: mixins.m });
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const toggleSidebar = () => setSidebar(!sidebar);

  const links = [
    {
      title: "Homepage",
      link: `${ROUTES.HOMEPAGE}`,
    },
    {
      title: "Rules",
      link: `${ROUTES.RULES}`,
    },
    {
      title: "Policy",
      link: `${ROUTES.POLICY}`,
    },
    {
      title: "Contacts",
      link: `${ROUTES.CONTACTS}`,
    },
  ];

  return (
    <header
      className={cn("sidebar", {
        show: sidebar,
        offset: scroll > 80,
      })}
    >
      <div className="sidebar-content">
        <div
          className={classNames("sidebar-header", {
            logged: false,
          })}
        >
          <Link to={ROUTES.HOME} className="sidebar-header-desk">
            <img src={logoImg} alt="logo" className="sidebar-header-logo" />
          </Link>
          <Link to={ROUTES.HOME} className="sidebar-header-mobile">
            <img src={logoMobileImg} alt="logo" className="sidebar-header-logo" />
          </Link>
          {isM && (
            <div className="sidebar-header-right">
              <button className="sidebar-header-btn" onClick={toggleSidebar}>
                <img alt="menu" src={sidebar ? closeImg : menuImg} />
              </button>
            </div>
          )}
        </div>
        {isM && (
          <div
            className={cn("header-sidebar", {
              active: sidebar,
            })}
          >
            {links.map((link, index) => (
              <button
                onClick={() => {
                  navigate(link.link || "");
                  setSidebar(false);
                }}
                key={index}
                className="sidebar-navigation-item"
              >
                {link.title}
                <div className="sidebar-navigation-item-coming">
                  <IconPolygon />
                  Coming Soon...
                </div>
              </button>
            ))}
          </div>
        )}
        {!isM && (
          <div className="sidebar-links">
            {links.map((link, index) => (
              <button
                onClick={() => {
                  navigate(link.link || "");
                  setSidebar(false);
                }}
                key={index}
                className={cn("sidebar-navigation-item", {
                  active: pathname === link.link,
                })}
              >
                {link.title}
                <div className="sidebar-navigation-item-line" />
                <div className="sidebar-navigation-item-coming">
                  <IconPolygon />
                  Coming Soon...
                </div>
              </button>
            ))}
          </div>
        )}
        {!isM && (
          <div className="sidebar-right">
            <LanguageSwitcher className="actions-item" />
          </div>
        )}
      </div>
    </header>
  );
};
