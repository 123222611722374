import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import "./App.scss";
import ExchangePage from "./pages/ExchangePage";
import ExchangeRules from "./pages/ExchangeRules";
import ExchangePolicy from "./pages/ExchangePolicy";
import ExchangeContacts from "./pages/ExchangeContacts";
import OrderPage from "./pages/OrderPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ExchangePage />} />
        <Route path="/policy" element={<ExchangePolicy />} />
        <Route path="/contacts" element={<ExchangeContacts />} />
        <Route path="/rules" element={<ExchangeRules />} />
        <Route path="/orders/:orderId" element={<OrderPage />} />
      </Routes>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
    </Router>
  );
}

export default App;
