import React, { useState, useEffect, useCallback, useRef } from "react";
import debounce from "lodash/debounce";
import "./Exchange.scss";
import If from "../If";
import SelectPayment from "./steps/SelectPayment";
import { SetDetails } from "./steps/SetDetails";
import { CreatingOrder } from "./steps/CreatingOrder";
import { Option, Network } from "../DropdownExchange";
import listSources from "../../services/listSources";
import findDirection, { DirectionInfo } from "../../services/findDiretion";
import calculateDirection, { CalculatedDirectionInfo } from "services/calculateDirection";

enum Steps {
  selectPayment,
  setDetails,
  creatingOrder,
}

const defaultOption: Option = {
  name: "",
  logo: "",
  info: undefined,
};

const defaultNetwork: Network = {
  id: 0,
  symbol: "",
  name: "",
};

const Exchange = () => {
  const [step, setStep] = useState<Steps>(Steps.selectPayment);
  const [sourceTokens, setSourceTokens] = useState<any[]>([]);
  const [sourceNetworks, setSourceNetworks] = useState<any[]>([]);
  const [targetFiats, setTargetFiats] = useState<any[]>([]);
  const [targetCurrencies, setTargetCurrencies] = useState<any[]>([]);

  const [selectedToken, setSelectedToken] = useState<Option>(defaultOption);
  const [selectedFiat, setSelectedFiat] = useState<Option>(defaultOption);
  const [selectedNetwork, setSelectedNetwork] = useState<Network>(defaultNetwork);
  const [selectedCurrency, setSelectedCurrency] = useState<Network>(defaultNetwork);

  const [amount, setAmount] = useState<number | undefined>();
  const [receiveAmount, setReceiveAmount] = useState<number | undefined>();

  const [directionInfo, setDirectionInfo] = useState<DirectionInfo | undefined>();
  const [calculatedInfo, setCalculatedInfo] = useState<CalculatedDirectionInfo | undefined>();

  const [sourceLoading, setSourceLoading] = useState(false);
  const [targetLoading, setTargetLoading] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);

  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  const [webSocketStatus, setWebSocketStatus] = useState<{ id: string; status: string; timestamp: number }>();
  const socketRef = useRef<WebSocket | null>(null);

  const setInputLoadingWithDelay = (value: boolean) => {
    setInputLoading(value);
    setTimeout(() => setInputLoading(false), 1500);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCalculateDirection = useCallback(
    debounce((value, directionId) => {
      calculateDirection(value, directionId).then((calculatedDirection) => {
        setCalculatedInfo(calculatedDirection);
        setInputLoading(false);
      });
    }, 500),
    []
  );

  const getMaxWidthClass = (step: number) => {
    switch (step) {
      case Steps.selectPayment:
        return "max-width-step1";
      case Steps.setDetails:
        return "max-width-step2";
      case Steps.creatingOrder:
        return "max-width-step3";
      default:
        return "";
    }
  };

  useEffect(() => {
    const connectWebSocket = () => {
      // Create WebSocket connection
      socketRef.current = new WebSocket("wss://api.coinflex.co/live");

      socketRef.current.onopen = () => {};

      socketRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          setWebSocketStatus({
            id: data.id,
            status: data.status,
            timestamp: data.timestamp,
          });
        } catch (error) {}
      };

      socketRef.current.onerror = (event) => {
        console.error("WebSocket error observed:", event);
      };

      socketRef.current.onclose = (event) => {
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();
  }, []);

  useEffect(() => {
    if (amount && calculatedInfo) {
      setReceiveAmount(calculatedInfo.amount);
    } else {
      setReceiveAmount(undefined);
      setInputLoadingWithDelay(true);
    }
  }, [amount, calculatedInfo]);

  useEffect(() => {
    if (calculatedInfo) {
      setInputLoadingWithDelay(true);
    }
  }, [calculatedInfo]);

  useEffect(() => {
    setTargetCurrencies(selectedFiat?.info);
  }, [selectedFiat]);

  useEffect(() => {
    if (targetCurrencies) {
      setSelectedCurrency(targetCurrencies[0]);
    }
  }, [targetCurrencies]);

  useEffect(() => {
    setSourceNetworks(selectedToken?.info);
    if (sourceNetworks) setSelectedNetwork(sourceNetworks[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToken]);

  useEffect(() => {
    setSourceLoading(true);
    listSources("crypto")
      .then((listPayments: any) => {
        setSelectedToken(listPayments[0]);
        setSourceTokens(listPayments);
        setSourceNetworks(listPayments[0].info);
        setSourceLoading(false);
      })
      .catch(() => setSourceLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedNetwork && selectedNetwork.id !== 0) {
      listSources("fiat", selectedNetwork.id)
        .then((listPayments) => {
          setSelectedFiat(listPayments[0]);
          setTargetFiats(listPayments);
          setTargetCurrencies(listPayments[0].info);
          setTargetLoading(false);
        })
        .catch(() => setTargetLoading(false));
    }
  }, [selectedNetwork]);

  const onTokenAmountChange = (value: number | undefined) => {
    setInputLoadingWithDelay(true);
    setAmount(value);
  };

  useEffect(() => {
    if (selectedCurrency && selectedNetwork && selectedCurrency.id !== 0 && selectedNetwork.id !== 0) {
      const cryptoId = selectedNetwork.id;
      const fiatId = selectedCurrency.id;
      findDirection(cryptoId, fiatId).then((direction) => {
        setDirectionInfo(direction);
      });
    }
  }, [selectedCurrency, selectedNetwork]);

  useEffect(() => {
    if (directionInfo && amount) {
      const value = amount;
      const directionId = directionInfo.id;
      debouncedCalculateDirection(value, directionId);
    }
  }, [directionInfo, amount, debouncedCalculateDirection]);

  return (
    <div className={`bridgeExchangeContainer ${getMaxWidthClass(step)}`}>
      <div className="bridgeContainer__actions">
        <If condition={step === Steps.selectPayment}>
          <SelectPayment
            onNext={() => setStep(Steps.setDetails)}
            sourceTokens={sourceTokens}
            sourceNetworks={sourceNetworks}
            targetFiats={targetFiats}
            targetCurrencies={targetCurrencies}
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
            selectedFiat={selectedFiat}
            setSelectedFiat={setSelectedFiat}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            sourceLoading={sourceLoading}
            targetLoading={targetLoading}
            inputLoading={inputLoading}
            onTokenAmountChange={onTokenAmountChange}
            amount={amount}
            receiveAmount={receiveAmount}
            directionInfo={directionInfo}
            calculatedInfo={calculatedInfo}
            setInputLoading={setInputLoadingWithDelay}
          />
        </If>

        <If condition={step === Steps.setDetails}>
          <SetDetails
            onNext={() => setStep(Steps.creatingOrder)}
            onBack={() => setStep(Steps.selectPayment)}
            requirements={calculatedInfo?.requirements}
            inputValues={inputValues}
            setInputValues={setInputValues}
          />
        </If>
        <If condition={step === Steps.creatingOrder}>
          <CreatingOrder
            selectedToken={selectedToken}
            selectedCurrency={selectedCurrency}
            selectedFiat={selectedFiat}
            selectedNetwork={selectedNetwork}
            directionInfo={directionInfo}
            account={inputValues.cf6}
            inputValues={inputValues}
            webSocketStatus={webSocketStatus}
            amount={amount}
          />
        </If>
      </div>
    </div>
  );
};

export default Exchange;
